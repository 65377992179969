import { DateTime } from 'luxon';
import { observable, computed, action } from 'mobx';

import { getDate, getLocalDateTime } from 'helpers/datetime';

import { BaseFormModel, displayName, isRequired } from 'stores/BaseForm';
import { OrderTipsReportReqParams, PaymentType } from 'stores/Report/types';
import RootStore from 'stores/RootStore';
import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';

class OrderTipsReportForm extends BaseFormModel {
  @observable
  @isRequired()
  period: [DateTime, DateTime | undefined] = [
    getLocalDateTime(),
    getLocalDateTime(),
  ];

  @observable
  @displayName('PAYMENT_TYPE')
  paymentType: PaymentType | '' = '';

  @observable paymentTypes: PaymentType[] = [];

  @computed
  get requestParams(): OrderTipsReportReqParams {
    const [startDate, endDate] = this.period;

    return {
      from: getDate(startDate.toJSDate()),
      to: getDate(endDate!.toJSDate()),
      ...(this.paymentType ? { paymentType: this.paymentType } : {}),
    };
  }

  @computed
  get paymentTypesValues() {
    return this.paymentTypes.slice().map(pt => ({
      value: pt.toString(),
      label: RootStore.localization.formatMessage(pt.toString()),
    }));
  }

  @action
  async init() {
    try {
      const paymentTypesData = await httpFacade.order.fetchPaymentTypes();

      this.paymentTypes = paymentTypesData.data;
    } catch (error) {
      Log.info(error);
    }
  }
}

export default OrderTipsReportForm;
