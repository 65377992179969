import http from 'http/http';
import { api } from 'http/helpers';
import URLS from 'http/urls';

import { CurrencyValue } from 'types/general';

import {
  SubsidyReport,
  RevenueReport,
  StornoReport,
  RevenueReportReqParams,
  StornoReportReqParams,
  SubsidyReportReqParams,
  PaymentType,
  IMenuItemReport,
  IOrderTipsReport,
  OrderTipsReportReqParams,
} from 'stores/Report/types';

interface ItemRequest {
  tag: string;
  amount: CurrencyValue;
}

export const orderApi = {
  sendOrder(userId: string, products: ItemRequest[]) {
    return http.post(api(URLS.order.hostess), {
      userId,
      products,
    });
  },

  fetchRevenueReport(params: RevenueReportReqParams) {
    return http.get<RevenueReport>(api(URLS.order.report.revenue), {
      params,
    });
  },

  fetchRevenueReportPdf(params: RevenueReportReqParams) {
    return http.get<any>(api(URLS.order.report.revenuePdf), {
      params,
      headers: {
        Accept: '*/*',
      },
      responseType: 'blob',
    });
  },

  fetchRevenueReportCsv(params: RevenueReportReqParams) {
    return http.get<any>(api(URLS.order.report.revenueCsv), {
      params,
      headers: {
        Accept: '*/*',
      },
      responseType: 'blob',
    });
  },

  fetchSubsidyReport(params: SubsidyReportReqParams) {
    return http.get<SubsidyReport>(api(URLS.order.report.discounts), {
      params,
    });
  },

  fetchSubsidyReportPdf(params: SubsidyReportReqParams) {
    return http.get<any>(api(URLS.order.report.discountsPdf), {
      params,
      headers: {
        Accept: '*/*',
      },
      responseType: 'blob',
    });
  },

  fetchSubsidyReportCsv(params: SubsidyReportReqParams) {
    return http.get<any>(api(URLS.order.report.discountsCsv), {
      params,
      headers: {
        Accept: '*/*',
      },
      responseType: 'blob',
    });
  },

  fetchStornoReport(params: StornoReportReqParams) {
    return http.get<StornoReport>(api(URLS.order.report.storno), {
      params,
    });
  },

  fetchStornoReportPdf(params: StornoReportReqParams) {
    return http.get<any>(api(URLS.order.report.stornoPdf), {
      params,
      headers: {
        Accept: '*/*',
      },
      responseType: 'blob',
    });
  },

  fetchStornoReportCsv(params: StornoReportReqParams) {
    return http.get<any>(api(URLS.order.report.stornoCsv), {
      params,
      headers: {
        Accept: '*/*',
      },
      responseType: 'blob',
    });
  },

  fetchItemReport(params: RevenueReportReqParams) {
    return http.get<IMenuItemReport[]>(api(URLS.order.report.item), {
      params,
    });
  },

  fetchItemReportPdf(params: RevenueReportReqParams) {
    return http.get<any>(api(URLS.order.report.itemPdf), {
      params,
      headers: {
        Accept: '*/*',
      },
      responseType: 'blob',
    });
  },

  fetchItemReportCsv(params: RevenueReportReqParams) {
    return http.get<any>(api(URLS.order.report.itemCsv), {
      params,
      headers: {
        Accept: '*/*',
      },
      responseType: 'blob',
    });
  },

  fetchOrderTipsReportPdf(params: OrderTipsReportReqParams) {
    return http.get<any>(api(URLS.order.report.orderTipsPdf), {
      params,
      headers: {
        Accept: '*/*',
      },
      responseType: 'blob',
    });
  },

  fetchOrderTipsReportCsv(params: OrderTipsReportReqParams) {
    return http.get<any>(api(URLS.order.report.orderTipsCsv), {
      params,
      headers: {
        Accept: '*/*',
      },
      responseType: 'blob',
    });
  },

  fetchOrderTipsReport(params: RevenueReportReqParams) {
    return http.get<IOrderTipsReport[]>(api(URLS.order.report.tips), {
      params,
    });
  },

  fetchPaymentTypes() {
    return http.get<PaymentType[]>(api(URLS.order.report.paymentTypes));
  },
};
