import { action, observable } from 'mobx';
import {
  IOrderTipsReport,
  OrderTipsReportReqParams,
  SortProperty,
} from './types';
import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';
import IReportStore from './IReportStore';
import { downloadPdfBlob } from 'helpers/pdf';
import { downloadCsvBlob } from 'helpers/csv';
import RootStore from 'stores/RootStore';
import { showDate } from 'helpers/datetime';

class OrderTipsReportStore implements IReportStore {
  @observable report: IOrderTipsReport[];
  @observable loading = false;
  sortProperty: SortProperty | null = { id: 'amount', desc: true };

  @action.bound
  async getReport(params: OrderTipsReportReqParams) {
    try {
      this.loading = true;

      const { data } = await httpFacade.order.fetchOrderTipsReport(params);

      this.report = data;
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async getPdfReport(params: OrderTipsReportReqParams) {
    try {
      const fileName = RootStore.localization.formatMessage(
        'filename.report.order.tips.pdf',
        {
          range:
            params.from !== params.to
              ? `${showDate(params.from)} - ${showDate(params.to)}`
              : showDate(params.from),
        },
      );

      const { data } = await httpFacade.order.fetchOrderTipsReportPdf({
        ...params,
      });
      const pdfBlobURL = URL.createObjectURL(data);
      const pdfBlob = new Blob([data], { type: 'application/pdf' });
      downloadPdfBlob({ fileName, pdfBlob, pdfBlobURL });
    } catch (error) {
      Log.error(error);
    }
  }

  @action.bound
  async getCsvReport(params: OrderTipsReportReqParams) {
    try {
      const fileName = RootStore.localization.formatMessage(
        'filename.report.order.tips.csv',
        {
          range:
            params.from !== params.to
              ? `${showDate(params.from)} - ${showDate(params.to)}`
              : showDate(params.from),
        },
      );
      const { data } = await httpFacade.order.fetchOrderTipsReportCsv({
        ...params,
      });
      const csvBlobURL = URL.createObjectURL(data);
      const csvBlob = new Blob([data], { type: 'text/csv' });
      downloadCsvBlob({ fileName, csvBlob, csvBlobURL });
    } catch (error) {
      Log.error(error);
    }
  }

  @action.bound
  changeSortProperty(sortProperty: SortProperty | null) {
    this.sortProperty = sortProperty;
  }
}

export default OrderTipsReportStore;
