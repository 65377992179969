const URLS = {
  config: '/config',
  locale: `/assets/i18n/:locale.json`,

  authToken: '/oauth/token',
  registration: '/self-reg',
  oAuthAuthorization: '/oauth2/authorize',
  login: '/login',

  users: {
    staff: '/staff/:id',
    campaigns: '/campaigns/:id',
    roles: '/roles/:id',
    groups: '/userGroups/:id',
    companies: '/userCompanies/:id',
    deposit: '/users/:id/deposit',
    financeSummary: '/v2/users/:userId/finance/summary',
    financeReport: '/v2/users/:id/finance/report',
    passwordRecovery: '/users/recoveryPassword',
    changePassword: '/users/:id/changePassword',
    receipt: '/v2/users/:userId/transactions/:transactionId/report',
  },

  employees: {
    paymentQR: '/employees/:id/paymentQrCode',
    employees: '/employees/:id',
    accountant: '/employees/accountant',
    NFC: '/employees/nfc/:nfc',
    QR: '/employees/qr_code/:qr',
    assignTag: '/employees/:userId/assign',
    resetTag: '/employees/:userId/reset',
    refund: '/employees/:id/markRefunded',
    RFID: '/employees/rfid/:rfid',
    unmarkRefund: '/employees/:id/unmarkRefunded',
    blockProfile: '/employees/:id/block',
    unblockProfile: '/employees/:id/unblock',
    dishPreference: '/employees/:id/dishPreference',
    requestRefund: '/employees/:id/requestRefund',
    cardDeposit: '/employees/:id/card-deposit',
  },

  order: {
    report: {
      report: '/v2/orders/report',
      revenueDaily: '/v2/orders/report/dailyRevenue',
      revenue: '/v2/orders/report/revenue',
      revenuePdf: '/v2/orders/report/pdf/revenue',
      revenueCsv: '/v2/orders/report/csv/revenue',
      discounts: '/v2/orders/report/discounts',
      discountsPdf: '/v2/orders/report/pdf/discounts',
      discountsCsv: '/v2/orders/report/csv/discounts',
      storno: '/v2/orders/report/storno',
      stornoPdf: '/v2/orders/report/pdf/storno',
      stornoCsv: '/v2/orders/report/csv/storno',
      paymentTypes: '/v2/orders/report/payment-types',
      item: '/v2/orders/report/item',
      itemPdf: '/v2/orders/report/pdf/item',
      itemCsv: '/v2/orders/report/csv/item',
      tips: '/v2/orders/report/tips',
      orderTipsPdf: '/v2/orders/report/pdf/tips',
      orderTipsCsv: '/v2/orders/report/csv/tips',
    },
    PDF: '/v2/orders/report/pdf',
    hostess: '/orders/hostess',
  },

  payment: {
    request: '/payment/request',
  },

  finance: {
    refillBalance: '/v2/users/:userId/balance',
    subsidies: '/subsidies/basic/:id',
    subsidiesBayer: '/subsidies/bayer/:id',
    discounts: '/discounts/fixedRate/:id',
    vats: '/vats',
  },

  menu: {
    menu: '/menu/:from/:to',
    update: '/menu',
    delete: '/menu/line/:id/:from/:to',
    menuStandard: '/menu/standard',
    moveLine: '/menu/move/line',
    lines: '/menuLines/:id',
    additionalSpecifications: '/specifications/additional',
    baseSpecifications: '/specifications/base',
    dishGroups: '/dishgroups/:id',
    dishGroupItems: '/dishgroups/:id/menuItems',
    isExtendedAvailable: '/specifications/extendedAvailable',
    menuOnlineOrder: '/menu/:date/onlineOrder',
    menuItemsCount: '/menu/:from/:to/count',
    changeNotVisibleKioskSelfCheckout:
      '/dishgroups/:id/changeNotVisibleKioskSelfCheckout',
  },

  menuItems: {
    item: '/menuItems/:id',
    items: '/menuItems/all',
    itemsPageable: '/menuItems',
    itemsSearch: '/menuItems/searchByTitle',
    itemsTypes: '/menuItems/types',
    markSubsidizedItem: '/menuItems/:id/markSubsidized',
    markSubsidizedDishGroup: '/menuItems/markSubsidized',
    allergens: '/menuItems/allergens',
    labels: '/menuItems/labels/:id',
    depositItems: '/menuItems/deposit',
    menuItemImages: '/menuItems/:id/images',
    menuItemImage: '/menuItems/:id/image',
    menuItemsCardImages: '/menuItems/images',
    changeNotVisibleKioskSelfCheckout:
      '/menuItems/:id/changeNotVisibleKioskSelfCheckout',
  },

  recognition: {
    inferenceAgents: '/cluster/inference',
    taskAgents: '/cluster/task',
    datasets: '/datasets/:id',
    copyDataset: '/datasets/:sourceDatasetId/copy',
    importDatasetArtifact: '/datasets/artifact',
    models: '/mlmodels/:id',
    uploadSample: '/samples/file',
    samples: '/samples/:id',
    samplesPageable: '/samples/pageable',
    bulkSamplesDeleting: '/samples/bulk/delete',
    startTrainJob: '/jobs/train',
    cancelTrainJob: '/jobs/train/:id/cancel',
    enableLabelling: '/mlmodels/:id/marklabelling',
    enableDefault: '/mlmodels/:id/mark-inference-default',
    autoLabelling: '/detections/autolabel',

    tasks: '/tasks/:id',
    taskAction: '/tasks/:id/:action',
    tasksQueued: '/tasks/queued',
    tasksSearch: '/tasks/search',

    agentsReports: '/cluster/deployment',
    enableDeployment: '/deployments/enable/:id',
    disableDeployment: '/deployments/disable/:id',
    deployments: '/deployments/:id',
  },

  onlineOrder: {
    timeSlotsAll: '/v2/orders/online/deliveryTimeSlots/all/:date',
    timeSlots: '/v2/orders/online/deliveryTimeSlots',
    employeeOrders: '/v2/orders/online/employee/:id',
    orders: '/v2/orders/online',
    orderPreview: '/v2/orders/online/preview',
    feedback: '/v2/orders/online/feedback',

    onlineOrders: '/v2/orders/online',
    orderDelivered: '/v2/orders/online/:id/markDelivered',
    orderProcessingFinished: '/v2/orders/online/:id/orderProcessingFinished',
    searchByQr: '/v2/orders/online/qrCode/:qrPayload',
    searchTodayByQr: '/v2/orders/online/qrCode/today/:qrPayload',
    searchById: '/v2/orders/online/:id',
    searchByQueueNumber: '/v2/orders/online/queueNumber/:queueNumber',
    orderLocations: '/v2/orders/online/deliveryLocations',
  },

  report: {
    report: '/report',
  },

  catering: {
    caterings: '/caterings',
    currentCatering: '/caterings/my',
  },

  archive: {
    searchArchive: '/primus/orders/date/:date',
    searchImages: '/primus/orders/:id/attachments',
    getImage: '/primus/orders/attachments/:attachmentId',
  },

  log: {
    error: '/log/error',
  },
};

export default URLS;
