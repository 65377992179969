import { action } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from 'components/Button/Button';
import Datepicker from 'components/Form/Fields/Datepicker/Datepicker';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import ActionSelect from 'components/ActionSelect/ActionSelect';

import OrderTipsReportForm from 'stores/Forms/Reports/OrderTipsReportForm';
import OrderTipsReportStore from 'stores/Report/OrderTipsReportStore';

import style from './OrderTipsReport.module.scss';
import SelectField from 'components/Form/Fields/Select/SelectField';
import RootStore from 'stores/RootStore';

interface Props {
  form: OrderTipsReportForm;
  store: OrderTipsReportStore;
}

@observer
class OrderTipsForm extends Component<Props> {
  formControl = bindFormControl(this.props.form);

  options = [
    {
      title: 'title.pdf',
      action: () =>
        this.props.store.getPdfReport(this.props.form.requestParams),
    },
    {
      title: 'title.csv',
      action: () =>
        this.props.store.getCsvReport(this.props.form.requestParams),
    },
  ];

  @action.bound
  async getReport() {
    const form = this.props.form;

    if (form.isFormValid) {
      await this.props.store.getReport(form.requestParams);
    }
  }

  render() {
    const { form } = this.props;
    const FormControl = this.formControl;

    return (
      <form className={style.form}>
        <div className={style.form__field}>
          <label className={style.form__label}>
            <FormattedMessage id="title.period" />
          </label>

          <FormControl
            name="period"
            render={props => (
              <Datepicker
                {...props}
                styles={{
                  calendar: style.datepicker,
                  button: style.datepicker__btn,
                }}
                value={form.period}
                selectRange
              />
            )}
          />
        </div>

        <div className={style.form__field}>
          <FormControl
            name="paymentType"
            className={style.form__field}
            render={props => (
              <SelectField
                {...props}
                options={[
                  {
                    value: '',
                    label: RootStore.localization.formatMessage('title.all'),
                  },
                  ...form.paymentTypesValues,
                ]}
              />
            )}
          />
        </div>

        <Button
          className={style.button}
          onClick={this.getReport}
          disabled={!form.isFormValid}
        >
          <FormattedMessage id="button.generate.report" />
        </Button>

        <ActionSelect
          title="title.export.report"
          options={this.options}
          styles={{ buttonStyle: style.btn }}
        />
      </form>
    );
  }
}

export default OrderTipsForm;
