import React, { Component } from 'react';
import { observer } from 'mobx-react';

import OrderTipsReportForm from 'stores/Forms/Reports/OrderTipsReportForm';
import OrderTipsReportStore from 'stores/Report/OrderTipsReportStore';

import style from './OrderTipsReport.module.scss';
import Spinner from 'components/Spinner/Spinner';
import OrderTipsForm from '../OrderTipsReport/OrderTipsForm';
import ReportTable from './ReportTable';

@observer
class OrderTipsReportPage extends Component {
  store = new OrderTipsReportStore();
  form = new OrderTipsReportForm();

  async componentDidMount() {
    await Promise.all([
      this.form.init(),
      this.store.getReport(this.form.requestParams),
    ]);
  }

  render() {
    const { report } = this.store;

    return (
      <section className={style.main}>
        {this.store.loading && <Spinner contain />}

        <OrderTipsForm form={this.form} store={this.store} />

        {report && (
          <ReportTable
            report={report}
            tableStyle={style.table__single}
            store={this.store}
          />
        )}
      </section>
    );
  }
}

export default OrderTipsReportPage;
