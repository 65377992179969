import React from 'react';
import { withArrow } from 'components/Table/helpers';
import { Column } from 'react-table';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';

const config = (): Column[] => [
  {
    Header: withArrow('title.date'),
    accessor: 'date',
    resizable: false,
    width: 160,
  },
  {
    Header: withArrow('title.report.total'),
    accessor: 'allAmount',
    resizable: false,
    width: 200,
    Cell: row => <FormattedCurrency value={row.value} withSymbol={false} />,
  },
  {
    Header: withArrow('title.report.cash'),
    accessor: 'cashAmount',
    resizable: false,
    width: 200,
    Cell: row => <FormattedCurrency value={row.value} withSymbol={false} />,
  },
  {
    Header: withArrow('title.report.cash.base.vat'),
    accessor: 'cashBaseVat',
    resizable: false,
    width: 200,
    Cell: row => <FormattedCurrency value={row.value} withSymbol={false} />,
  },
  {
    Header: withArrow('title.report.cash.reduced.vat'),
    accessor: 'cashReducedVat',
    resizable: false,
    width: 200,
    Cell: row => <FormattedCurrency value={row.value} withSymbol={false} />,
  },
  {
    Header: withArrow('title.report.noncash'),
    accessor: 'nonCashAmount',
    resizable: false,
    width: 200,
    Cell: row => <FormattedCurrency value={row.value} withSymbol={false} />,
  },
  {
    Header: withArrow('title.report.noncash.base.vat'),
    accessor: 'nonCashBaseVat',
    resizable: false,
    width: 200,
    Cell: row => <FormattedCurrency value={row.value} withSymbol={false} />,
  },
  {
    Header: withArrow('title.report.noncash.reduced.vat'),
    accessor: 'nonCashReducedVat',
    resizable: false,
    width: 200,
    Cell: row => <FormattedCurrency value={row.value} withSymbol={false} />,
  },
];

export default config;
